document.addEventListener('DOMContentLoaded', function () {
	// TODO: Change this to querySelectorAll and then loop through all of the elements
	var calendars = document.querySelectorAll('.simcal-fullcalendar-grid .simcal-fullcal');

	calendars.forEach(function (calendarElement) {
		var currentView = null;

		var bubbleTrigger = calendarElement.getAttribute('data-event-bubble-trigger') === 'hover' ? 'mouseenter' : 'click';
		var defaultView = calendarElement.getAttribute('data-default-view');
		if (defaultView === 'month') {
			defaultView = 'dayGridMonth';
		} else if (defaultView === 'agendaWeek') {
			defaultView = 'timeGridWeek';
		} else {
			defaultView = 'timeGridDay';
		}

		var dayGridMonth = calendarElement.getAttribute('data-month-button') ? 'dayGridMonth ' : '';

		var timeGridWeek = calendarElement.getAttribute('data-week-button') ? 'timeGridWeek ' : '';

		var timeGridDay = calendarElement.getAttribute('data-day-button') ? 'timeGridDay' : '';

		var pagingButtons = calendarElement.getAttribute('data-paging-buttons');
		pagingButtons = pagingButtons === '" ' ? '' : pagingButtons;

		var timeFormat = {
			hour: calendarElement.getAttribute('data-date-format-hour'),
			minute:
				calendarElement.getAttribute('data-date-format-minute') === 'none'
					? '2-digit'
					: calendarElement.getAttribute('data-date-format-minute'),
			hour12: calendarElement.getAttribute('data-date-format-hour12') === '12hour' ? true : false,
			omitZeroMinute: calendarElement.getAttribute('data-date-format-minute') === 'none' ? true : false,
		};

		// Add meridiem
		if (calendarElement.getAttribute('data-date-format-meridiem') !== 'uppercase') {
			if (calendarElement.getAttribute('data-date-format-meridiem') === 'none') {
				timeFormat.meridiem = false;
			} else {
				timeFormat.meridiem = calendarElement.getAttribute('data-date-format-meridiem');
			}
		}

		var calendar = new FullCalendar.Calendar(calendarElement, {
			eventDisplay: 'block',
			initialView: defaultView,
			firstDay: calendarElement.getAttribute('data-week-starts'),
			fixedWeekCount: false,
			initialDate: calendarElement.getAttribute('data-start'),
			dayMaxEventRows: calendarElement.getAttribute('data-event-limit'),
			eventColor: calendarElement.getAttribute('data-event-color'),
			eventTextColor: calendarElement.getAttribute('data-text-color'),
			scrollTime: calendarElement.getAttribute('data-default-start-time'),
			slotMinTime: calendarElement.getAttribute('data-display-min-time'),
			slotMaxTime: calendarElement.getAttribute('data-display-max-time'),
			locale: calendarElement.getAttribute('data-display-language'),
			eventTimeFormat: timeFormat,
			headerToolbar: {
				start:
					pagingButtons +
					(calendarElement.getAttribute('data-today-button')
						? (' ' + calendarElement.getAttribute('data-today-button')).trim()
						: ''),
				center: 'title',
				end: (dayGridMonth + timeGridWeek + timeGridDay).trim(),
			},

			timeZone: calendarElement.getAttribute('data-timezone'),
			events: {
				url: simcal_fullcal.settings.ajax_url,
				method: 'POST',
				extraParams: {
					action: 'simcal_fullcal_load_events',
					calendar_id: calendarElement.getAttribute('data-calendar-id'),
				},
				failure: function (response) {
					console.log('Error loading events', response);
				},
			},
			eventClick: function (info) {
				info.jsEvent.preventDefault();
			},
			datesSet: function (info) {
				currentView = info.view.type;
			},
			eventDidMount: function (info) {
				var currentContent = document.querySelector('.simcal-fullcal-qtip-id-' + info.event.extendedProps.qtip_id);

				if (currentContent && currentContent.innerHTML) {
					tippy(info.el, {
						allowHTML: true,
						content: currentContent.innerHTML,
						theme: 'light',
						interactive: true,
						trigger: bubbleTrigger,
						appendTo: document.body,
					});
				}
			},
		});
		calendar.render();
	});
});
